.menu-link {
  color: rgb(106, 96, 87);
  text-decoration: none;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin-top: 2rem;
}

.plus {
  margin: 0 0.4rem;
}