.landing-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.recipe {
  width: 20rem;
  height: 20rem;
  border: 1px solid rgb(106, 96, 87);
  text-decoration: none;
  color: #b6b2ae;
  display: flex;
  align-items: flex-end;
  margin: 2rem;
  padding: 1rem;
}

@media screen and (max-width: 600px) {
  .landing-container {
    flex-direction: column;
  }

  .recipe {
    margin-bottom: 0;
  }

  .recipe:last-child {
    margin-bottom: 2rem;
  }
}