.cocktail-container {
  margin: 2rem auto;
  max-width: 1000px;
}

.cocktail-warning {
  font-size: 1rem;
  color: #bc4040;
  margin: 1rem;
  padding: 0.5rem;
  border: 1px solid #bc4040;
}

.cocktail-container h1 {
  font-size: 2.5rem;
}

.sub-container {
  border: 1px solid rgb(106, 96, 87);
  font-size: 1.75rem;
}

.description-container,
.serve-container,
.garnish-container,
.glassware-container,
.ingredients-container {
  padding: 1rem;
}

.section-heading {
  font-size: 1rem;
}

.ingredient:hover {
  cursor: pointer;
  color: #b6b2ae;
}

.ingredient.no-hover:hover {
  color: rgb(106, 96, 87);
  cursor: auto;
}

.ingredient-recipe {
  font-size: 1.2rem;
  flex: 0.5;
}

.ingredient-recipe-item {
  display: flex;
}

.ingredient-details-container {
  display: flex;
  flex: 1;
}

.ingredient-instructions {
  font-size: 1.2rem;
  flex: 0.5;
}

.ingredient-instructions::before {
  content: "Instructions";
  color: #b6b2ae;
  font-size: 1rem;
}

.ingredient-recipe::before {
  content: "Ingredients";
  color: #b6b2ae;
  font-size: 1rem;
}

@media screen and (max-width: 600px) {
  .ingredient-details-container {
    flex-direction: column;
  }

  .ingredient-instructions {
    margin-top: 1rem;
  }
}


@media screen and (max-width: 1000px) {
  .sub-container {
    border: none;
    font-size: 1.2rem;
  }

  .section-heading {
    font-size: 0.9rem;
  }

  .cocktail-container h1 {
    margin-left: 1rem;
  }

  .ingredient-instructions::before {
    font-size: 0.9rem;
  }

  .ingredient-recipe::before {
    font-size: 0.9rem;
  }

}