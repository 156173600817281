@font-face {
  font-family: 'Staat';
  src:
    local('Staat'),
    url(./fonts/staat/bold/Staat-Bold-webfont.woff2) format('woff2'),
    url(./fonts/staat/bold/Staat-Bold-webfont.woff) format('woff');
}

@font-face {
  font-family: 'StaatItalic';
  src:
    local('StaatItalic'),
    url(./fonts/staat/bold-italic/Staat-Bold-Italic-webfont.woff2) format('woff2'),
    url(./fonts/staat/bold-italic/Staat-Bold-Italic-webfont.woff) format('woff');
}

body {
  margin: 0;
  font-family: 'Staat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(21, 19, 27);
  color: rgb(106, 96, 87);
  font-size: calc(0.8 * 2.4rem);
  font-weight: 400;
  letter-spacing: calc(0.8 * .06rem);
}

.light-text {
  color: #b6b2ae;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}